import PropTypes from "prop-types";
import { createElement } from "react";

import "./title.scss";
import { buildClassName } from "../../../helpers/className";

/**
 * Title component
 * @param {object} props
 * @param {"h1"|"h2"|"h3"|"h4"|"h5"|"h6"|"p"} [props.variant="h1"]
 * @param {"light"|"normal"|"bold"} [props.weight="bold"]
 * @param {"small"|"medium"|"large"} [props.size="large"]
 * @param {"primary"|"secondary"} [props.color="primary"]
 * @param {boolean} [props.secondaryFont=false]
 * @param {boolean} [props.center=false]
 * @param {ReactNode} props.children
 */
export default function Title({
    variant = "h1",
    weight = "bold",
    size = "large",
    color = "primary",
    secondaryFont = false,
    center = false,
    children,
}) {
    return createElement(variant, {
        className: buildClassName(
            `title weight-${weight} size-${size}`,
            center && "align-center",
            secondaryFont && "family-secondary",
            color && `color-${color}`,
        ),
        dangerouslySetInnerHTML: { __html: children },
    });
}

Title.propTypes = {
    variant: PropTypes.string,
    weight: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    secondaryFont: PropTypes.bool,
    center: PropTypes.bool,
    children: PropTypes.node,
};
