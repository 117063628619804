import { createElement } from "react";
import PropTypes from "prop-types";

import { buildClassName } from "../../../helpers/className";

import "./text.scss";

/**
 * Text component
 * @param {object} props
 * @param {"light"|"normal"|"bold"} [props.weight="normal"]
 * @param {"small"|"normal"} [props.size="normal"]
 * @param {"primary"|"secondary"} [props.color="primary"]
 * @param {boolean} [props.secondaryFont=false]
 * @param {boolean} [props.center=false]
 * @param {ReactNode} props.children
 */
export default function Text({
    id = "",
    variant = "p",
    weight = "normal",
    size = "normal",
    color = "primary",
    secondaryFont = false,
    center = false,
    innerHtml,
    children,
}) {
    return createElement(
        variant,
        {
            ...(id ? { id } : {}),
            className: buildClassName(
                `text weight-${weight} size-${size}`,
                center && "align-center",
                secondaryFont && "family-secondary",
                color && `color-${color}`,
            ),
            dangerouslySetInnerHTML: innerHtml ? { __html: innerHtml } : null,
        },
        !innerHtml ? children : null,
    );
}

Text.propTypes = {
    id: PropTypes.string,
    variant: PropTypes.string,
    weight: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    secondaryFont: PropTypes.bool,
    center: PropTypes.bool,
    innerHtml: PropTypes.string,
    children: PropTypes.node,
};
