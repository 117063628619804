/**
 * @enum {string}
 */
export const PAGE = {
    login: "login",
    usernameRecovery: "recovery-username",
    passwordRecovery: "recovery-password",
    createAccount: "create-account",
};

/**
 * @enum {string}
 */
export const STAGE = {
    Init: "Init",
    Undefined: "Undefined",
    ServiceError: "ServiceError",

    LoginSuccess: "LoginSuccess",
    LoginFailure: "LoginFailure",
    LoginCollectCredentials: "LoginCollectCredentials",
    LoginCollectCredentialsPassword: "LoginCollectCredentialsPassword",

    CsamRedirect: "CsamRedirect",
    CsamError: "CsamError",

    PasskeyLogin: "PasskeyLogin",
    PasskeyUsername: "PasskeyUsername",
    PasskeyLoginError: "PasskeyLoginError",
    PasskeyRegistration: "PasskeyRegistration",
    PasskeyRegistrationChoice: "PasskeyRegistrationChoice",

    RecoveryUsernameNRN: "RecoveryUsernameNRN",
    RecoveryUsernameChannelChoice: "RecoveryUsernameChannelChoice",
    RecoveryUsernameConfirmation: "RecoveryUsernameConfirmation",
};
