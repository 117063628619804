/* eslint-disable import/prefer-default-export */

/**
 * Validate Belgian National Registry Number
 * @param {string} value
 * @returns {boolean}
 */
export function validateNationalRegistryNumber(value) {
    if (!value) return false;
    if (!/^[0-9]{11}$/.test(value)) return false;
    const valueToCheck = value.slice(0, 9);
    const checkDigit = value.slice(9, 11);

    let isValid = false;

    const check = (97 - (parseInt(valueToCheck, 10) % 97)).toString();
    isValid = check === checkDigit;

    if (!isValid) {
        const y2kValueToCheck = `${2}${valueToCheck}`;
        const y2kCheck = (97 - (parseInt(y2kValueToCheck, 10) % 97)).toString();
        isValid = y2kCheck === checkDigit;
    }

    return isValid;
}
