/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */

/**
 * @typedef {'none' | 'error' | 'debug'} LogLevel
 */

const logLevels = {
    none: 0,
    error: 10,
    debug: 20,
};

/** @type {LogLevel} */
let logLevel = "error";

/**
 * @param {string} prefix
 */
function create(prefix) {
    return {
        asyncFn: logAsyncFn(prefix),
        message: logMessage(prefix),
    };
}

/**
 * @param {string} prefix
 * @returns {(asyncFn: Promise<any>, name: string) => Promise<any>}
 */
function logAsyncFn(prefix) {
    return (asyncFn, name) =>
        asyncFn.then(
            (result) => {
                if (logLevels[logLevel] >= logLevels.debug) {
                    console.log(`${prefix}${name} success:`, result);
                }
                return result;
            },
            (error) => {
                if (logLevels[logLevel] >= logLevels.error) {
                    console.error(`${prefix}${name} rejected:`, error);
                }
                throw error;
            },
        );
}

/**
 * @param {string} prefix
 * @returns {(message: any, name: string) => void}
 */
function logMessage(prefix) {
    return (message, name) => {
        if (logLevels[logLevel] < logLevels.debug) return;
        console.log(`${prefix}${name}:`, message);
    };
}

/**
 * @param {LogLevel} level
 */
function setLevel(level) {
    logLevel = level;
}

const Logger = {
    create,
    setLevel,
};

export { Logger };
