import { createElement, useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { STAGE } from "../../config";

import { useAppState } from "../../hooks/useApp";
import { trimSlashes } from "../../helpers/app";
import { useDebug, useDebugActions } from "../../hooks/useDebug";

import { environmentContext } from "../../context/environment";

let stageHistory = [];

/**
 * @typedef {import('react').ReactElement} ReactElement
 */

/**
 * Router component
 * @param {object} props
 * @param {ReactElement | ReactElement[]} props.children
 * @returns {ReactElement}
 */
export function Router({ children }) {
    const { route } = useContext(environmentContext);
    const { setDebugStages } = useDebugActions();

    const routeToShow = useMemo(() => {
        if (!children || !children.length) {
            return children;
        }

        return (
            children.find((child) => {
                if (!child.props.path) {
                    return null;
                }
                if (typeof child.props.path === "string") {
                    return trimSlashes(child.props.path) === route;
                }
                return child.props.path.find(
                    (path) => trimSlashes(path) === route,
                );
            }) || null
        );
    }, [route, children]);

    useEffect(() => {
        if (!routeToShow || !routeToShow.props.children) return;
        if (!routeToShow.props.children.length) {
            setDebugStages([routeToShow.props.children.props.stage]);
            return;
        }
        setDebugStages(
            routeToShow.props.children.map((child) => child.props.stage),
        );
    }, [routeToShow, setDebugStages]);

    return routeToShow || null;
}

Router.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
};

/**
 * Route component
 * @description This component is used as to group stages depending on the page url
 * @param {object} props
 * @param {string} props.path
 * @param {ReactElement} props.children
 * @param {(opts: { currentStage: STAGE, previousStage: STAGE, exitPage: () => void }) => void} props.onBack
 */
export function Route({ children, onBack = () => {} }) {
    const { currentStage } = useAppState();
    const { debugCurrentStage, debugMode } = useDebug();

    useEffect(() => {
        stageHistory = [];
        if (window.history.state !== null) return;
        window.history.pushState(stageHistory, "");
    }, []);

    useEffect(() => {
        function handlePopState(e) {
            e.preventDefault();
            if (stageHistory.length > 1) {
                const cs = stageHistory.pop();
                const ps = stageHistory[stageHistory.length - 1];
                onBack({
                    currentStage: cs,
                    previousStage: ps,
                    exitPage: () => window.history.back(),
                });
                window.history.pushState(stageHistory, "");
            }
        }

        window.addEventListener("popstate", handlePopState);
        return () => window.removeEventListener("popstate", handlePopState);
    }, [onBack]);

    const stageToShow = useMemo(() => {
        if (!children || !children.length) {
            return children;
        }

        let shownStage = "";
        if (debugMode) {
            shownStage = debugCurrentStage || currentStage;
        } else {
            shownStage = currentStage;
        }

        return (
            children.find((child) => isStage(child.props.stage, shownStage)) ||
            children.find((child) =>
                isStage(child.props.stage, STAGE.Undefined),
            )
        );
    }, [children, currentStage, debugCurrentStage, debugMode]);

    useEffect(() => {
        stageHistory.push(currentStage);
        window.history.replaceState(stageHistory, "");
    }, [stageToShow, currentStage]);

    return stageToShow || null;
}

Route.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]).isRequired,
    onBack: PropTypes.func,
};

/**
 * Stage component
 * @param {object} props
 * @param {string} props.stage
 * @param {ReactElement} props.component
 * @param {object} props.props
 */
export function Stage({ component, props }) {
    return createElement(component, props);
}

Stage.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    stage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    component: PropTypes.func.isRequired,
    props: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

function isStage(stageStringOrArray, stage) {
    if (typeof stageStringOrArray === "string") {
        return stageStringOrArray === stage;
    }

    return stageStringOrArray.includes(stage);
}
