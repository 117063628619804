export async function loadPageData({ context, id, locale }) {
    try {
        const response = await fetch(
            getAPIPath(context, "page", id, { locale }),
        );
        if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
        }
        return await response.json();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
    }
}

export async function loadNotifications({
    context,
    id,
    locale,
    editMode,
    instanceId,
}) {
    try {
        const response = await fetch(
            getAPIPath(context, "message", id, {
                locale,
                client_id: getClientId(editMode, instanceId) || undefined,
            }),
        );

        const notificationsRaw = await response.json();
        return notificationsRaw.map((notification) => ({
            message: notification.messageContent,
            type: notification.messageType,
        }));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }

    return [];
}

function getAPIPath(context, route, id, options = {}) {
    const searchParams = new URLSearchParams(options);
    return `${context}/.rest/data/${route}/${id}?${searchParams.toString()}`;
}

function getClientId(editMode, instanceId) {
    if (editMode) {
        return `${instanceId}-mymut-app`;
    }

    const gotoURLString = new URL(window.location.href).searchParams.get(
        "goto",
    );
    if (gotoURLString === null) {
        // eslint-disable-next-line no-console
        console.warn("No goto found");
        return null;
    }

    const clientId = new URL(gotoURLString).searchParams.get("client_id");
    if (clientId === null) {
        // eslint-disable-next-line no-console
        console.warn("No client ID found");
        return null;
    }

    return clientId;
}
