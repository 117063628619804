import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";

import { STAGE } from "../config";

// eslint-disable-next-line no-unused-vars
import {
    Config,
    firstStep,
    getError,
    getStage,
    languageMiddleware,
    redirect,
    redirectOnLoginSuccess,
    shouldResume,
} from "../services/forgeRock";

import useURL from "../hooks/useURL";
import { Logger } from "../helpers/logging";

/**
 * @typedef {import("../services/forgeRock").FRStep} FRStep
 */

/**
 * @typedef {Object} AppState
 * @property {FRStep | string} currentStep
 * @property {Object} currentStepError
 * @property {STAGE} currentStage
 * @property {string} currentInstance
 * @property {string} currentLanguage
 * @property {any} serviceError
 * @property {Object[]} languages
 * @property {Object} redirectUrl
 */

/**
 * @type {AppState}
 */
const initialContext = {
    currentStep: STAGE.Init,
    currentStepError: undefined,
    currentStage: STAGE.Init,
    currentInstance: "author",
    currentLanguage: "en",
    serviceError: undefined,
    languages: [],
    redirectUrl: undefined,
};

/**
 * @type {React.Context<AppState>}
 */
export const appContext = createContext();

/**
 * @type {React.Context<(appAction: AppAction) => void>}
 */
export const appDispatchContext = createContext();

export function AppProvider({ environment, children }) {
    const [context, dispatch] = useReducer(appReducer, initialContext);
    const { searchParams } = useURL();

    // Initialise the ForgeRock SDK and get the first step
    useEffect(() => {
        if (
            environment.loading ||
            environment.error ||
            !environment.fesUrl ||
            !environment.instance
        ) {
            return;
        }

        // Could be defined by an environment variable
        Logger.setLevel("debug");

        if (searchParams.success) {
            dispatch({ type: "SET_STAGE", payload: STAGE.LoginSuccess });
            window.location.replace(searchParams.success);
            return;
        }

        /** @type {import("@forgerock/javascript-sdk/src/index").ConfigOptions} */
        const forgeRockConfig = {
            serverConfig: {
                baseUrl: environment.fesUrl,
                timeout: 30000,
            },
            middleware: [languageMiddleware(environment.locale)],
        };

        if (searchParams.realm || environment.instance)
            forgeRockConfig.realmPath =
                searchParams.realm || `/${environment.instance}`;
        if (searchParams.service) forgeRockConfig.tree = searchParams.service;

        Config.set(forgeRockConfig);

        const isResume = shouldResume(searchParams);

        firstStep(isResume)
            .then((newStep) => {
                if (redirectOnLoginSuccess(newStep)) return;

                const newStage = getStage(newStep);
                if (newStage === STAGE.CsamRedirect) {
                    redirect(newStep);
                    return;
                }
                dispatch({ type: "SET_STEP", payload: newStep });
            })
            .catch((e) => {
                dispatch({ type: "SET_SERVICE_ERROR", payload: e });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [environment]);

    useEffect(() => {
        dispatch({ type: "SET_REDIRECT_URL", payload: searchParams.goto });
    }, [searchParams, environment]);

    return (
        <appContext.Provider value={context}>
            <appDispatchContext.Provider value={dispatch}>
                {children}
            </appDispatchContext.Provider>
        </appContext.Provider>
    );
}

AppProvider.propTypes = {
    environment: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.string,
        fesUrl: PropTypes.string,
        instance: PropTypes.string,
        locale: PropTypes.string,
        localeMap: PropTypes.objectOf(PropTypes.string),
        magnolia: PropTypes.shape({
            id: PropTypes.string,
            context: PropTypes.string,
            instanceId: PropTypes.string,
        }),
    }).isRequired,
    children: PropTypes.node.isRequired,
};

/**
 * @typedef {Object} AppAction
 * @property {('SET_STEP'|'SET_STAGE'|'SET_SERVICE_ERROR'|'UPDATE_DYNAMIC_DATA'|'SET_REDIRECT_URL')} type
 * @property {any} payload
 */

/**
 * @param {AppState} state
 * @param {AppAction} action
 * @returns {AppState}
 */
function appReducer(state, action) {
    switch (action.type) {
        case "SET_STEP":
            return {
                ...state,
                currentStep: action.payload,
                currentStage: getStage(action.payload),
                currentStepError: getError(action.payload),
                serviceError: undefined,
            };

        case "SET_STAGE":
            return {
                ...state,
                currentStage: action.payload,
            };

        case "SET_REDIRECT_URL":
            return {
                ...state,
                redirectUrl: action.payload,
            };

        case "SET_SERVICE_ERROR":
            return {
                ...state,
                currentStep: undefined,
                currentStage: STAGE.ServiceError,
                currentStepError: undefined,
                serviceError: action.payload,
            };

        default: {
            // eslint-disable-next-line no-console
            console.warn(
                "Unknown action in App context",
                action.type,
                action.payload,
            );
            return state;
        }
    }
}
