import Bowser from "bowser";
import {
    FRStep,
    FRAuth,
    Config,
    CallbackType,
    FRLoginFailure,
    FRLoginSuccess,
    FRWebAuthn,
} from "@forgerock/javascript-sdk";

import { STAGE } from "../config";

import { hasPasskeyChoice } from "./localStorage";
import { STORE_KEY_USERNAME } from "../helpers/const";
import { Logger } from "../helpers/logging";

const log = Logger.create("[ForgeRock]");

// This is a HACK to avoid the stupid ForgeRock SDK from doing weird stuff
// When importing the types and classes from the SDK, it considers it as a new instance everytime
// wich breaks all behaviours, this way we keep the same instance.

export { FRStep, FRAuth, Config, CallbackType, FRLoginFailure, FRLoginSuccess };

/**
 *
 * @param {FRStep} step
 * @returns {STAGE}
 */
export function getStage(step) {
    if (step === STAGE.Init) return STAGE.Init;
    if (!step || !step.type) return STAGE.Undefined;

    if (step instanceof FRLoginSuccess) {
        return STAGE.LoginSuccess;
    }

    if (step instanceof FRLoginFailure) {
        return STAGE.LoginFailure;
    }

    if (step.getStage()) {
        return step.getStage();
    }

    const redirectCallback = step.getCallbacksOfType(
        CallbackType.RedirectCallback,
    );
    if (redirectCallback.length > 0) {
        return STAGE.CsamRedirect;
    }

    return STAGE.Undefined;
}

/**
 * Is the provided variable a ForgeRock step
 * @param {FRStep | any} providedStep
 * @returns {boolean}
 */
export function isForgeRockStep(providedStep) {
    return providedStep && providedStep instanceof FRStep;
}

/**
 * Get step choices
 * @param {FRStep | string} step
 * @returns {string[]}
 */
export function getStepChoices(step) {
    const choiceCallbacks = transformFRErrorToWarning(() =>
        step.getCallbacksOfType?.(CallbackType.ChoiceCallback),
    );
    return (choiceCallbacks?.[0] && choiceCallbacks[0].getChoices()) || null;
}

/**
 * @param {FRStep | string} step
 * @param {string} choiceValue
 * @returns {number}
 */
export function getStepChoiceIndex(step, choiceValue) {
    const choices = getStepChoices(step);
    return choices ? choices.indexOf(choiceValue) : -1;
}

/**
 * Get step input value
 * @param {FRStep | string} step
 * @param {CallbackType} callbackType
 * @returns {string}
 */
export function getStepInputValue(step, callbackType) {
    return transformFRErrorToWarning(() =>
        step.getCallbackOfType?.(callbackType)?.getInputValue(),
    );
}

/**
 * Set step input value
 * @param {FRStep | string} step
 * @param {CallbackType} callbackType
 * @param {string} value
 * @returns {void}
 */
export function setStepInputValue(step, callbackType, value) {
    transformFRErrorToWarning(() =>
        step.getCallbackOfType?.(callbackType)?.setInputValue(value),
    );
}

/**
 * @param {boolean} resume
 * @returns {Promise<FRStep|ERROR_CODES>}
 */
export function firstStep(resume = false) {
    const query = new URLSearchParams(window.location.search);
    const queryObject = Object.fromEntries(query.entries());

    log.message(
        resume ? window.location.href : queryObject,
        `${resume ? "Resume" : "Init"} payload`,
    );

    const action = resume
        ? log.asyncFn(FRAuth.resume(window.location.href), "Resume")
        : log.asyncFn(FRAuth.next(STAGE.Init, { query: queryObject }), "Init");

    return action;
}

/**
 * Go to the next step
 * @param {FRStep|string} currentStep
 * @returns {Promise<FRStep|ERROR_CODES>}
 */
export function nextStep(currentStep) {
    const { middleware } = Config.get();
    log.message(currentStep, "NextStep payload");
    return log.asyncFn(
        FRAuth.next(currentStep, {
            middleware: [...middleware, firstLoginMiddleware()],
        }),
        "NextStep",
    );
}

/**
 * Redirect on login success
 * @param {FRStep} step
 * @param {Function} [actionBeforeRedirect]
 * @returns {boolean}
 */
export function redirectOnLoginSuccess(step, actionBeforeRedirect = () => {}) {
    const stage = getStage(step);
    if (stage === STAGE.LoginSuccess && step?.getSuccessUrl) {
        actionBeforeRedirect?.();
        window.location.search = `success=${encodeURIComponent(
            step.getSuccessUrl(),
        )}`;

        return true;
    }

    return false;
}

/**
 * Redirect to the next step
 * @param {FRStep} step
 * @returns {void}
 */
export function redirect(step) {
    const redirectCallback = step.getCallbacksOfType(
        CallbackType.RedirectCallback,
    );
    if (redirectCallback.length === 0) {
        return;
    }

    try {
        FRAuth.redirect(step);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
    }
}

/**
 * Should resume the session
 * @param {URLSearchParams} searchParams
 * @returns {boolean}
 */
export function shouldResume(searchParams) {
    // eslint-disable-next-line camelcase
    const { code, state, form_post_entry } = searchParams;
    const isComeback =
        (code !== undefined && state !== undefined) ||
        // eslint-disable-next-line camelcase
        form_post_entry !== undefined;

    if (isComeback) {
        return !!localStorage.getItem(FRAuth.previousStepKey);
    }

    localStorage.removeItem(FRAuth.previousStepKey);
    return false;
}

/**
 * Go to the next passkey step
 * @param {FRStep} currentStep
 * @returns {Promise<{step: FRStep, stage: STAGE}>}
 */
export function nextPasskeyStep(currentStep) {
    const { browser, os } = Bowser.parse(window.navigator.userAgent);

    const action =
        getStage(currentStep) === STAGE.PasskeyRegistration
            ? FRWebAuthn.register(
                  currentStep,
                  `${browser.name} (${os.name}${
                      os.versionName ? ` ${os.versionName}` : ""
                  })`,
              )
            : FRWebAuthn.authenticate(currentStep);
    return action.then((step) => ({ step, stage: getStage(step) }));
}

/**
 * firstLoginMiddleware is a ForgeRock SDK middleware that defines firstLogin
 * @param {FRStep} currentStep
 * @returns {Function(req: Object, action: Object, next: Function): void}
 */
export function firstLoginMiddleware() {
    return (req, _, next) => {
        if (
            !req.init?.body ||
            !localStorage.getItem(STORE_KEY_USERNAME) ||
            hasPasskeyChoice(localStorage.getItem(STORE_KEY_USERNAME))
        )
            return;

        req.url.searchParams.set("firstLogin", true);
        next();
    };
}

/**
 * languageMiddleware is a ForgeRock SDK middleware that defines the user language
 * @param {string} locale
 * @returns {Function(req: Object, action: Object, next: Function): void}
 */
export function languageMiddleware(locale) {
    return (req, action, next) => {
        if (action.type !== "START_AUTHENTICATE") return next();
        req.init.headers.append("Accept-Language", locale.toLowerCase());
        return next();
    };
}

/**
 * Get error from step
 * @param {FRStep|FRLoginSuccess|FRLoginFailure} step
 * @returns {{ hasError: boolean, [key: string]: string }}
 */
export function getError(step) {
    if (getStage(step) === STAGE.LoginFailure) {
        return {
            error_message: "login_failure",
        };
    }

    const metadata = transformFRErrorToWarning(() =>
        step?.getCallbackOfType?.(CallbackType.MetadataCallback),
    );

    const data = metadata?.getData();
    if (!data || Object.keys(data).length === 0) {
        return null;
    }
    return data;
}

/**
 * Transform a ForgeRock SDK error to a warning
 * @param {Function} fn
 */
function transformFRErrorToWarning(fn) {
    try {
        return fn();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn("ForgeRock SDK ERROR:", e);
        return null;
    }
}
