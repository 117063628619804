/* eslint-disable import/prefer-default-export */
import { useCallback, useContext, useEffect, useState } from "react";
import { taggingContext } from "../context/tagging";

/**
 * This hook is used to handle the tagging of the page.
 *
 *    It will use the tagViewOptions to call the view tagging.
 *
 *    It will also return a function to call the event (link) tagging.
 * @param {{[key: string]: string}} tagViewOptions
 * @returns { { tagEvent: (opts: { [key: string]: string }) => void } }
 */
export function useTagging(tagViewOptions) {
    const { tagView, tagEvent, error } = useContext(taggingContext);
    const [viewTagged, setViewTagged] = useState(false);

    useEffect(() => {
        if (viewTagged || !tagViewOptions || error || !tagView) return;
        tagView(tagViewOptions);
        setViewTagged(true);
    }, [tagView]); // eslint-disable-line react-hooks/exhaustive-deps

    const secureTagEvent = useCallback(
        (opts) => {
            if (error || !tagEvent) return;
            tagEvent(opts);
        },
        [tagEvent, error],
    );

    return { tagEvent: secureTagEvent };
}
