export function loadUTag() {
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;

    // Fill with correct information when ready
    const url = "";
    const scriptElement = document.createElement("script");
    scriptElement.id = "utag";
    scriptElement.src = url;
    scriptElement.type = "text/javascript";
    scriptElement.crossOrigin = "anonymous";
    scriptElement.async = true;

    document.getElementsByTagName("head")[0].appendChild(scriptElement);

    return new Promise((resolve, reject) => {
        scriptElement.onload = (e) => {
            resolve(e);
        };
        scriptElement.onerror = (e) => {
            reject(e);
        };
    });
}

export function getUTag() {
    return window.utag;
}
