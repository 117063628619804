import { useEffect, useMemo, useState } from "react";

import { STAGE } from "../../config";

import {
    CHOICES_VALUES,
    STORE_KEY_USERNAME,
    INSTANCE_526,
} from "../../helpers/const";
import { useAppActions, useAppState } from "../../hooks/useApp";
import { useContent } from "../../hooks/useContent";
import {
    CallbackType,
    getStepChoiceIndex,
    getStepChoices,
} from "../../services/forgeRock";

import Button from "../../components/atoms/button/button";
import Title from "../../components/atoms/title/title";
import Spacer from "../../components/atoms/spacer/spacer";
import Link from "../../components/atoms/link/link";
import Text from "../../components/atoms/text/text";

import Page, { PageBody } from "../../components/molecules/page/page";
import { addPasskeyChoice } from "../../services/localStorage";
import Alert from "../../components/molecules/alert/alert";
import useErrors from "../../hooks/useErrors";
import { useTagging } from "../../hooks/useTagging";

export default function PasskeyPage() {
    const {
        biometricTitle,
        biometricLogoUrl,
        biometricDescription,
        biometricSubmitButtonLabel,
        biometricCancelButtonLabel,
        biometricLegend,
        biometricLegendUrl,
    } = useContent();

    const {
        currentStep,
        currentStage,
        currentInstance,
        currentStepError,
        currentLanguage,
    } = useAppState();
    const { submitCurrentStep } = useAppActions();

    const { tagEvent } = useTagging({
        page_name: "login",
        page_cat1: "biometry",
        page_cat2: "single sign on",
        page_path: `/MyPartenamut/fr/login.html/${currentLanguage}/biometry/`,
        env_template: "external",
        env_language: currentLanguage,
        customer_journey: "Je veux me connecter",
    });

    const { getErrorLabel } = useErrors();

    const [passkeyEnableLoading, setPasskeyEnableLoading] = useState(false);
    const [passkeyCancelLoading, setPasskeyCancelLoading] = useState(false);

    const passkeyAcceptIndex = useMemo(
        () =>
            getStepChoiceIndex(currentStep, CHOICES_VALUES.PASSKEY_SETUP_TRUE),
        [currentStep],
    );

    const passkeyCancelIndex = useMemo(
        () =>
            getStepChoiceIndex(currentStep, CHOICES_VALUES.PASSKEY_SETUP_FALSE),
        [currentStep],
    );

    const errorLabel = useMemo(() => {
        if (!currentStepError) return null;
        return (
            getErrorLabel(currentStepError.error_message) ||
            currentStepError.error_message
        );
    }, [currentStepError, getErrorLabel]);

    const handleChoiceClick = (oEvent) => {
        const choiceIndex =
            oEvent.currentTarget.getAttribute("data-choice-index");

        tagEvent({
            event_name: "login",
            event_detail1: "login-request",
            event_detail2: "biometry",
            event_detail3: "single sign on",
        });

        // eslint-disable-next-line eqeqeq
        if (choiceIndex == passkeyAcceptIndex) {
            setPasskeyEnableLoading(true);
            // eslint-disable-next-line eqeqeq
        } else if (choiceIndex == passkeyCancelIndex) {
            setPasskeyCancelLoading(true);
        }

        const choiceLabel = getStepChoices(currentStep)[choiceIndex];
        if (choiceLabel === "false") {
            addPasskeyChoice(localStorage.getItem(STORE_KEY_USERNAME));
        }

        submitCurrentStep(currentStep, [
            { type: CallbackType.ChoiceCallback, value: choiceIndex },
        ]);
    };

    const is526 = currentInstance === INSTANCE_526;

    useEffect(() => {
        if (!passkeyEnableLoading || currentStage === STAGE.PasskeyRegistration)
            return;
        setPasskeyEnableLoading(false);
    }, [currentStage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Page>
            <PageBody center>
                <Spacer size={is526 ? "3rem" : "2rem"} />
                {errorLabel ? (
                    <>
                        <Alert variant="danger" innerHtml={errorLabel} />
                        <Spacer size="2rem" />
                    </>
                ) : null}
                <img
                    src={biometricLogoUrl}
                    width="140px"
                    alt="biometric choice"
                />
                <Spacer size={is526 ? "2rem" : "1rem"} />
                <div style={{ maxWidth: is526 ? "384px" : "inherit" }}>
                    <Title
                        weight="normal"
                        size={is526 ? "medium-large" : "medium"}
                        center
                    >
                        {biometricTitle}
                    </Title>
                    {is526 ? <Spacer size="1rem" /> : null}
                    <Title
                        variant="h2"
                        size="small"
                        color={is526 ? "primary" : "secondary"}
                        weight={is526 ? "light" : "bold"}
                        secondaryFont={!is526}
                        center
                    >
                        {biometricDescription}
                    </Title>
                </div>
                <Spacer size="2rem" />
                <Button
                    variant="primary"
                    type="button"
                    choiceIndex={passkeyAcceptIndex}
                    onClick={handleChoiceClick}
                    fullSize
                    loading={passkeyEnableLoading}
                >
                    {biometricSubmitButtonLabel}
                </Button>
                <Spacer size=".5rem" />
                <Button
                    variant="secondary"
                    type="button"
                    choiceIndex={passkeyCancelIndex}
                    onClick={handleChoiceClick}
                    fullSize
                    loading={passkeyCancelLoading}
                >
                    {biometricCancelButtonLabel}
                </Button>
                <Spacer size={is526 ? "2rem" : ".5rem"} />
                {is526 && biometricLegend ? (
                    <Link size="small" href={biometricLegendUrl}>
                        {biometricLegend}
                    </Link>
                ) : null}
                {!is526 && biometricLegend ? (
                    <Text
                        color="secondary"
                        secondaryFont
                        weight="light"
                        center
                        innerHtml={biometricLegend}
                    />
                ) : null}
                <Spacer size="2rem" />
            </PageBody>
        </Page>
    );
}
