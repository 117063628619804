/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";

import { useState } from "react";
import { buildClassName } from "../../helpers/className";
import { useAppState } from "../../hooks/useApp";

import "./routerDebug.scss";
import { useDebug, useDebugActions } from "../../hooks/useDebug";

// eslint-disable-next-line import/prefer-default-export
export function RouterDebug() {
    const { debugMode } = useDebug();
    return debugMode ? <RouterDebugUI /> : null;
}

function RouterDebugUI() {
    const { currentStage } = useAppState();
    const { debugCurrentStage, debugStages } = useDebug();
    const { setDebugStage } = useDebugActions();

    const [openTray, setOpenTray] = useState(false);
    const selectedStage = debugCurrentStage || currentStage;

    return (
        <div className={buildClassName("debug-router", openTray && "open")}>
            <div className="debug-router-tray">
                <div className="tray-header">
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        className="debug-router-toggle"
                        type="button"
                        onClick={() => setOpenTray(!openTray)}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: openTray
                                ? "close&nbsp;&nbsp;►"
                                : "debug&nbsp;&nbsp;◄",
                        }}
                    />
                </div>
                <div className="tray-content">
                    <TrayGroup title="stages">
                        {debugStages.map((stage) => (
                            <div
                                className={buildClassName(
                                    "route-toggle",
                                    selectedStage === stage.id && "active",
                                )}
                                key={stage.id}
                                onClick={() => setDebugStage(stage.id)}
                            >
                                {stage.label}
                            </div>
                        ))}
                    </TrayGroup>
                </div>
            </div>
        </div>
    );
}

function TrayGroup({ title, children }) {
    const [open, setOpen] = useState(true);

    return (
        <div className={buildClassName("tray-group", open && "open")}>
            <button
                className="tray-group-toggle"
                onClick={() => setOpen(!open)}
                type="button"
            >
                <p>{title}</p>
            </button>
            <div className="tray-group-content">{children}</div>
        </div>
    );
}

TrayGroup.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};
