import { Route, Router, Stage } from "./components/router/router";

import Notifications from "./components/molecules/notifications/notifications";

import ErrorPage from "./pages/error/error";
import LoginPage from "./pages/login/login";
import PasskeyPage from "./pages/login/passkey";
import PasswordPage from "./pages/login/password";
import SuccessPage from "./pages/login/success";
import LoadingPage from "./pages/loading/loading";
import RecUsrNrnPage from "./pages/recovery/username/nationalRegistryNumber";
import RecUsrChannelChoicePage from "./pages/recovery/username/channelChoice";
import RecUsrConfirmationPage from "./pages/recovery/username/confirmation";

import { PAGE, STAGE } from "./config";
import { RouterDebug } from "./components/debug/routerDebug";
import { CallbackType, getStepChoiceIndex } from "./services/forgeRock";
import { CHOICES_VALUES } from "./helpers/const";
import { useAppActions, useAppState } from "./hooks/useApp";

export default function App() {
    const { currentStep } = useAppState();
    const { submitCurrentStep } = useAppActions();

    return (
        <>
            <Notifications />
            <div className="router-wrapper">
                <Router>
                    <Route
                        path={[
                            PAGE.login,
                            `${PAGE.login}_en`,
                            `${PAGE.login}_fr`,
                        ]}
                        onBack={({ currentStage, exitPage }) => {
                            // We just get out of SSO and go back to the previous page
                            if (
                                [
                                    STAGE.Init,
                                    STAGE.LoginCollectCredentials,
                                    STAGE.CsamRedirect,
                                    STAGE.PasskeyLogin,
                                ].includes(currentStage)
                            ) {
                                exitPage();
                                return;
                            }

                            // We are in the password page, we need to go back to the login page
                            if (
                                currentStage ===
                                STAGE.LoginCollectCredentialsPassword
                            ) {
                                const choiceIndex = getStepChoiceIndex(
                                    currentStep,
                                    CHOICES_VALUES.PASSWORD_BACK,
                                );

                                submitCurrentStep(currentStep, [
                                    {
                                        type: CallbackType.ChoiceCallback,
                                        value: choiceIndex,
                                    },
                                ]);
                                return;
                            }

                            // We just reload the page to go back to the start of the SSO flow
                            window.location.reload();
                        }}
                    >
                        <Stage stage={STAGE.Init} component={LoadingPage} />
                        <Stage
                            stage={[
                                STAGE.LoginCollectCredentials,
                                STAGE.CsamRedirect,
                                STAGE.PasskeyLogin,
                            ]}
                            component={LoginPage}
                        />
                        <Stage
                            stage={STAGE.LoginCollectCredentialsPassword}
                            component={PasswordPage}
                        />
                        <Stage
                            stage={[
                                STAGE.PasskeyRegistrationChoice,
                                STAGE.PasskeyRegistration,
                            ]}
                            component={PasskeyPage}
                        />
                        <Stage
                            stage={STAGE.LoginSuccess}
                            component={SuccessPage}
                        />
                        <Stage
                            stage={[
                                STAGE.Undefined,
                                STAGE.ServiceError,
                                STAGE.LoginFailure,
                            ]}
                            component={ErrorPage}
                        />
                    </Route>
                    <Route path={PAGE.usernameRecovery}>
                        <Stage
                            stage={[
                                STAGE.Init,
                                STAGE.Undefined,
                                STAGE.RecoveryUsernameNRN,
                            ]}
                            component={RecUsrNrnPage}
                        />
                        <Stage
                            stage={STAGE.RecoveryUsernameChannelChoice}
                            component={RecUsrChannelChoicePage}
                        />
                        <Stage
                            stage={STAGE.RecoveryUsernameConfirmation}
                            component={RecUsrConfirmationPage}
                        />
                    </Route>
                </Router>
                <RouterDebug />
            </div>
        </>
    );
}
