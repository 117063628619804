import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { STAGE } from "../../config";

import {
    CallbackType,
    getStepChoiceIndex,
    isForgeRockStep,
} from "../../services/forgeRock";

import { useContent } from "../../hooks/useContent";
import { useAppActions, useAppState } from "../../hooks/useApp";
import { CHOICES_VALUES, INSTANCE_526 } from "../../helpers/const";

import Link from "../../components/atoms/link/link";
import Button from "../../components/atoms/button/button";
import Spacer from "../../components/atoms/spacer/spacer";
import { useTagging } from "../../hooks/useTagging";

export default function LoginCsam() {
    const { itsmeInfoLabel, itsmeInfoUrl, itsmeButtonLabel, itsmeButtonUrl } =
        useContent();
    const { currentStep, currentStage, currentInstance } = useAppState();
    const { submitCurrentStep } = useAppActions();
    const { tagEvent } = useTagging({});
    const [csamRedirectLoading, setCsamRedirectLoading] = useState(
        currentStage === STAGE.CsamRedirect,
    );

    const choiceIndex = useMemo(
        () =>
            isForgeRockStep(currentStep) &&
            getStepChoiceIndex(currentStep, CHOICES_VALUES.ITSME),
        [currentStep],
    );

    const handleItsmeClick = () => {
        tagEvent({
            event_name: "login",
            event_detail1: "login-request",
            event_detail2: "connect with csam - itsme, eid, ...",
            event_detail3: "single sign on",
        });

        submitCurrentStep(currentStep, [
            { type: CallbackType.ChoiceCallback, value: choiceIndex },
        ]);
        setCsamRedirectLoading(true);
    };

    const handleItsmeInfoUrlClick = () => {
        tagEvent({
            event_name: "login",
            event_detail1: "discover itsme",
            event_detail3: "single sign on",
        });
    };

    const is526 = currentInstance === INSTANCE_526;

    return (
        <>
            <Button
                choiceIndex={choiceIndex}
                type="button"
                variant="secondary"
                onClick={handleItsmeClick}
                fullSize={is526}
                loading={csamRedirectLoading}
            >
                <ButtonContent
                    label={itsmeButtonLabel}
                    imageUrl={itsmeButtonUrl}
                />
            </Button>
            {itsmeInfoUrl && itsmeInfoLabel ? (
                <>
                    <Spacer size={is526 ? "2rem" : "1rem"} />
                    <Link
                        onClick={handleItsmeInfoUrlClick}
                        hasPreventDefault={false}
                        href={itsmeInfoUrl}
                        size={is526 ? "small" : "normal"}
                        target="_blank"
                    >
                        {itsmeInfoLabel}
                    </Link>
                </>
            ) : null}
        </>
    );
}

function ButtonContent({ label, imageUrl }) {
    const [beforeImage, afterImage] = useMemo(
        // eslint-disable-next-line no-template-curly-in-string
        () => label?.split("${image}") || [label, ""],
        [label],
    );

    // eslint-disable-next-line no-template-curly-in-string
    return label?.includes("${image}") ? (
        <>
            {beforeImage}
            {imageUrl && afterImage ? (
                <img src={imageUrl} alt="itsme logo" />
            ) : null}
            {afterImage}
        </>
    ) : (
        label
    );
}

ButtonContent.propTypes = {
    label: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
};
