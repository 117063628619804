// 2 functions that handle an input mask where "0" are number placeholders "x" are letter placeholders any other character is a fixed character
// The first function is the mask function that takes a mask and a value and returns the masked value
// The second function is the unmask function that takes a mask and a masked value and returns the unmasked value

/**
 * Mask a value based on a mask
 * @param {string} value
 * @param {string} mask
 * @returns {string}
 */
export function maskValue(value, mask) {
    if (mask === undefined || mask === null || mask === "") {
        console.warn("maskValue: mask is required");
        return value;
    }

    if (value === undefined || value === null || value === "") {
        return "";
    }

    const maskChars = mask.split("");
    const valueChars = value.split("");
    let maskedValue = "";

    maskChars.forEach((char) => {
        if (valueChars.length === 0) return;

        if (char === "0") {
            if (!/\d/.test(valueChars[0])) return;
            maskedValue += valueChars.shift();
        } else if (char === "x") {
            if (!/[a-zA-Z]/.test(valueChars[0])) return;
            maskedValue += valueChars.shift();
        } else {
            maskedValue += char;
        }
    });

    return maskedValue;
}

/**
 * Unmask a value based on a mask
 * @param {string} maskedValue
 * @param {string} mask
 * @returns {string}
 */
export function unmaskValue(maskedValue, mask) {
    if (mask === undefined || mask === null || mask === "") {
        console.warn("unmaskValue: mask is required");
        return maskedValue;
    }

    if (
        maskedValue === undefined ||
        maskedValue === null ||
        maskedValue === ""
    ) {
        return "";
    }

    const maskChars = mask.split("");
    const maskedValueChars = maskedValue.split("");
    let value = "";

    maskChars.forEach((char) => {
        if (maskedValueChars.length === 0) return;

        if (char === "0") {
            while (!/\d/.test(maskedValueChars[0])) {
                maskedValueChars.shift();
                if (maskedValueChars.length === 0) return;
            }
            value += maskedValueChars.shift();
        } else if (char === "x") {
            while (!/[a-zA-Z]/.test(maskedValueChars[0])) {
                maskedValueChars.shift();
                if (maskedValueChars.length === 0) return;
            }
            value += maskedValueChars.shift();
        }
    });

    return value;
}
