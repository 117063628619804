import { useCallback, useContext } from "react";

import {
    getStage,
    isForgeRockStep,
    nextPasskeyStep,
    nextStep,
    redirect,
    redirectOnLoginSuccess,
    setStepInputValue,
} from "../services/forgeRock";

import { appContext, appDispatchContext } from "../context/app";
import { addPasskeyChoice } from "../services/localStorage";
import { STORE_KEY_USERNAME } from "../helpers/const";
import {
    environmentContext,
    environmentDispatchContext,
} from "../context/environment";
import { STAGE } from "../config";

/**
 * @typedef {import("../services/forgeRock").FRStep} FRStep
 */

export function useAppState() {
    const appContextState = useContext(appContext);
    const { instance, locale, languages } = useContext(environmentContext);
    appContextState.currentInstance = instance;
    appContextState.currentLanguage = locale;
    appContextState.languages = languages;
    return appContextState;
}

export function useAppActions() {
    const dispatchApp = useContext(appDispatchContext);
    const dispatchEnv = useContext(environmentDispatchContext);

    /**
     * Submit current step
     * @param {FRStep} step
     * @param {{ type: string, value: any }[]} submitValues
     * @returns {void}
     */
    const submitCurrentStep = useCallback(
        (step, submitValues = []) => {
            if (!isForgeRockStep(step)) return;

            if (submitValues.length) {
                submitValues.forEach(({ type, value }) => {
                    setStepInputValue(step, type, value);
                });
            }

            nextStep(step)
                .then((newStep) => {
                    if (redirectOnLoginSuccess(newStep)) return;

                    dispatchApp({ type: "SET_STEP", payload: newStep });

                    const newStage = getStage(newStep);
                    if (
                        newStage === STAGE.PasskeyLogin ||
                        newStage === STAGE.PasskeyRegistration
                    ) {
                        handlePasskeyStep(newStep, dispatchApp);
                        return;
                    }

                    if (newStage === STAGE.CsamRedirect) {
                        redirect(newStep);
                    }
                })
                .catch((e) => {
                    dispatchApp({ type: "SET_SERVICE_ERROR", payload: e });
                });
        },
        [dispatchApp],
    );

    /**
     * Update the language
     * @param {string} language
     * @param {boolean} forceReload
     */
    const updateLanguage = useCallback(
        (language, forceReload = false) => {
            dispatchEnv({
                type: "UPDATE_LANGUAGE",
                payload: { language, forceReload },
            });
        },
        [dispatchEnv],
    );

    return {
        submitCurrentStep,
        updateLanguage,
    };
}

/**
 * Handle the Passkey flow
 * @description This function is used to handle the passkey flow as there are multiple steps involved
 * @param {FRStep} step
 * @returns {void}
 */
function handlePasskeyStep(step, dispatch) {
    nextPasskeyStep(step)
        // Here we get submit the passkey data we got from the webauthn API
        // If there was an error we skip this and go to the catch block
        .then(({ step: newStep }) => nextStep(newStep))
        // Catch block is used to handle webauthn API errors
        .catch((e) => {
            // eslint-disable-next-line no-console
            console.warn("Webauthn API error", e);
            return nextStep(step);
        })
        .then((newStep) => {
            const newStage = getStage(newStep);
            // [TODO] For errors that we skip,
            // remember them in the context,
            // we can display them in the next STAGE
            if (newStage === STAGE.PasskeyLoginError) {
                return nextStep(newStep);
            }

            return newStep;
        })
        .then((newStep) => {
            const isSuccess = redirectOnLoginSuccess(newStep, () => {
                const previousStage = getStage(step);
                if (previousStage === STAGE.PasskeyRegistration) {
                    addPasskeyChoice(localStorage.getItem(STORE_KEY_USERNAME));
                }
            });
            if (isSuccess) return;

            dispatch({ type: "SET_STEP", payload: newStep });
        })
        .catch((e) => {
            dispatch({ type: "SET_SERVICE_ERROR", payload: e });
        });
}
