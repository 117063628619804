import { useMemo, useState } from "react";
import { useContent } from "../../../hooks/useContent";
import { useAppState } from "../../../hooks/useApp";
import { INSTANCE_526 } from "../../../helpers/const";
import Page, { PageBody } from "../../../components/molecules/page/page";
import Spacer from "../../../components/atoms/spacer/spacer";
import Title from "../../../components/atoms/title/title";
import Button from "../../../components/atoms/button/button";
import Text from "../../../components/atoms/text/text";

// Refactor when we have a better way to handle this
const confirmationType = "sms";

export default function RecUsrConfirmationPage() {
    const [submitLoading] = useState(false);
    const {
        confirmationLogoUrl,
        confirmationTitle,
        confirmationDescriptionEmail,
        confirmationDescriptionSms,
        confirmationLoginButtonLabel,
        confirmationLoginButtonUrl,
    } = useContent();
    const { currentInstance } = useAppState();
    const is526 = currentInstance === INSTANCE_526;

    const descriptionFilled = useMemo(() => {
        let message = "";
        if (confirmationType === "email" && confirmationDescriptionEmail) {
            message = confirmationDescriptionEmail;
        } else if (confirmationType === "sms" && confirmationDescriptionSms) {
            message = confirmationDescriptionSms;
        }

        return message.replace(
            // eslint-disable-next-line no-template-curly-in-string
            "${destination}",
            `[some destination ${confirmationType}]`,
        );
    }, [confirmationDescriptionEmail, confirmationDescriptionSms]);

    return (
        <Page>
            <PageBody center={is526}>
                <Spacer size={is526 ? "3rem" : "1.5rem"} />
                {confirmationLogoUrl ? (
                    <>
                        <img src={confirmationLogoUrl} alt="logo" />
                        <Spacer size={is526 ? "2rem" : "1.5rem"} />
                    </>
                ) : null}
                <div style={is526 ? { padding: "0 2rem" } : null}>
                    <Title
                        size={is526 ? "medium-large" : "large"}
                        center={is526}
                    >
                        {confirmationTitle}
                    </Title>
                </div>
                <Spacer size={is526 ? "3rem" : "1.5rem"} />
                <Text weight="light" innerHtml={descriptionFilled} />
                <Spacer size="2rem" />

                <Button
                    type="button"
                    href={confirmationLoginButtonUrl}
                    fullSize={is526}
                    loading={submitLoading}
                >
                    {confirmationLoginButtonLabel}
                </Button>
                <Spacer size={is526 ? "2rem" : "1.5rem"} />
            </PageBody>
        </Page>
    );
}
