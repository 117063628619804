import PropTypes from "prop-types";

import Icon from "../../../atoms/icon/icon";

import { buildClassName } from "../../../../helpers/className";

export default function FormControlBase({
    id = "",
    label = "",
    className = "",
    disabled = false,
    invalid = false,
    error = "",
    helper = null,
    children,
}) {
    return (
        <fieldset
            className={buildClassName(
                "form-control",
                disabled && "disabled",
                invalid && "invalid",
                className,
            )}
        >
            {label ? (
                <div className="form-label">
                    <label htmlFor={id}>{label}</label>
                    {helper && <div className="form-helper">{helper}</div>}
                </div>
            ) : null}
            {invalid && error ? (
                <div className="invalid-feedback">
                    <Icon variant="danger" size={1} />
                    <div
                        className="content"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: error }}
                    />
                </div>
            ) : null}
            <div className="form-control-input">{children}</div>
        </fieldset>
    );
}

FormControlBase.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
    helper: PropTypes.element,
    children: PropTypes.element,
};
