import PropTypes from "prop-types";
import FormControlBase from "./base";

export default function FormControlText({
    className = "",
    type = "",
    id = "",
    rows = "",
    value = "",
    placeholder = "",
    label = "",
    error = "",
    helper = null,
    invalid = false,
    disabled = false,
    onChange = null,
    onKeyDown = null,
    onBlur = null,
    extraElement = null,
    autoFocus = false,
    ariaDescribedBy = "",
}) {
    const handleChange = (oEvent) =>
        onChange && onChange(oEvent.target.value, oEvent);
    const handleKeyDown = (oEvent) => onKeyDown && onKeyDown(oEvent);
    const handleBlur = (oEvent) => onBlur && onBlur(oEvent);

    return (
        <FormControlBase
            id={id}
            className={className}
            error={error}
            disabled={disabled}
            helper={helper}
            invalid={invalid}
            label={label}
        >
            {type === "textarea" ? (
                <textarea
                    id={id}
                    name={id}
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    autoFocus={autoFocus}
                    aria-describedby={ariaDescribedBy || undefined}
                />
            ) : (
                <input
                    id={id}
                    name={id}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    autoFocus={autoFocus}
                    aria-describedby={ariaDescribedBy || undefined}
                />
            )}
            {extraElement ? <div className="extra">{extraElement}</div> : null}
        </FormControlBase>
    );
}

FormControlText.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    helper: PropTypes.element,
    invalid: PropTypes.bool,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onBlur: PropTypes.func,
    extraElement: PropTypes.element,
    autoFocus: PropTypes.bool,
    ariaDescribedBy: PropTypes.string,
};
