import PropTypes from "prop-types";

import FormControlBase from "./base";

import "./radio.scss";

export default function FormControlRadio({
    className = "",
    disabled = false,
    error = "",
    helper = null,
    id = "",
    invalid = false,
    label = "",
    selected = null,
    options = [],
    onChange = null,
}) {
    return (
        <FormControlBase
            className={className}
            disabled={disabled}
            error={error}
            helper={helper}
            id={id}
            invalid={invalid}
            label={label}
        >
            {options.map((oOption) => (
                <label
                    key={oOption.value}
                    htmlFor={oOption.value}
                    className="form-radio"
                >
                    <input
                        name={oOption.value}
                        id={oOption.value}
                        type="radio"
                        value={oOption.value}
                        checked={selected === oOption.value}
                        disabled={disabled}
                        onChange={() => onChange?.(oOption.value)}
                    />
                    <span className="form-radio-circle" />
                    <span>{oOption.label}</span>
                </label>
            ))}
        </FormControlBase>
    );
}

FormControlRadio.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    helper: PropTypes.element,
    id: PropTypes.string,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    selected: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    onChange: PropTypes.func,
};
