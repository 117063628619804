import React from "react";
import { createRoot } from "react-dom/client";

import App from "./app";
import { AppProvider } from "./context/app";

import "./scss/common/main.scss";
import { EnvironmentProvider, environmentContext } from "./context/environment";
import { ContentProvider } from "./context/content";
import { DebugProvider } from "./context/debug";
import { TaggingProvider } from "./context/tagging";

createRoot(document.getElementById("root")).render(
    <EnvironmentProvider>
        <environmentContext.Consumer>
            {(env) => (
                <TaggingProvider environment={env}>
                    <div className={`app theme-${env.instance}`}>
                        <DebugProvider environment={env}>
                            <ContentProvider environment={env}>
                                <AppProvider environment={env}>
                                    <App />
                                </AppProvider>
                            </ContentProvider>
                        </DebugProvider>
                    </div>
                </TaggingProvider>
            )}
        </environmentContext.Consumer>
    </EnvironmentProvider>,
);
