import Button from "../../components/atoms/button/button";
import Card from "../../components/atoms/card/card";
import Link from "../../components/atoms/link/link";
import Spacer from "../../components/atoms/spacer/spacer";
import Title from "../../components/atoms/title/title";
import { INSTANCE_526 } from "../../helpers/const";
import { useAppState } from "../../hooks/useApp";
import { useContent } from "../../hooks/useContent";
import { useTagging } from "../../hooks/useTagging";

const { Body: CardBody } = Card;

export default function LoginRegistration() {
    const {
        registerTitle,
        registrationUrl,
        registrationLabel,
        registrationTutoUrl,
        registrationTutoLabel,
    } = useContent();
    const { currentInstance } = useAppState();

    const { tagEvent } = useTagging({});

    const handleRegistration = () => {
        tagEvent({
            event_name: "login",
            event_detail1: "account creation",
            event_detail3: "single sign on",
        });
    };

    const handleRegistrationTutoUrl = () => {
        tagEvent({
            event_name: "login",
            event_detail1: "check video for quick account creation",
            event_detail3: "single sign on",
        });
    };

    const is526 = currentInstance === INSTANCE_526;

    return (
        <Card>
            <CardBody className={is526 ? "center" : ""}>
                <Spacer size="1.5rem" />
                <Title
                    variant="h2"
                    weight={is526 ? "bold" : "normal"}
                    size={is526 ? "small" : "medium"}
                    color={is526 ? "primary" : "secondary"}
                    center={is526}
                >
                    {registerTitle}
                </Title>
                <Spacer size={is526 ? "1rem" : "1.5rem"} />
                <Button
                    variant="secondary"
                    fullSize={is526}
                    href={registrationUrl}
                    onClick={handleRegistration}
                >
                    {registrationLabel}
                </Button>
                <Spacer />
                <Link
                    onClick={handleRegistrationTutoUrl}
                    hasPreventDefault={false}
                    href={registrationTutoUrl}
                    size={is526 ? "small" : "normal"}
                >
                    {registrationTutoLabel}
                </Link>
                <Spacer size="1.5rem" />
            </CardBody>
        </Card>
    );
}
