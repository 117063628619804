import * as React from "react";
import PropTypes from "prop-types";
import "./form.scss";

import FormControlText from "./controls/text";
import FormControlRadio from "./controls/radio";

function Form({
    className = "",
    method = null,
    action = null,
    onSubmit = () => {},
    children,
}) {
    const handleSubmit = (event) => {
        if (!method) {
            event.preventDefault();
        }
        onSubmit(event);
    };

    return (
        <form
            className={className}
            method={method}
            action={action}
            onSubmit={handleSubmit}
            autoComplete="off"
        >
            {children}
        </form>
    );
}

Form.propTypes = {
    className: PropTypes.string,
    action: PropTypes.string,
    method: PropTypes.string,
    onSubmit: PropTypes.func,
    children: PropTypes.node.isRequired,
};

export { Form, FormControlText, FormControlRadio };
