import React, { useState } from "react";

import { STAGE } from "../../config";

import { useContent } from "../../hooks/useContent";
import { useAppState } from "../../hooks/useApp";
import { getStepChoices } from "../../services/forgeRock";
import { INSTANCE_526 } from "../../helpers/const";

import Page, {
    PageBody,
    PageFooter,
} from "../../components/molecules/page/page";

import Spacer from "../../components/atoms/spacer/spacer";
import Separator from "../../components/atoms/separator/separator";

import LoginUsername from "./loginUsername";
import LoginRegistration from "./loginRegistration";
import LoginCsam from "./loginCsam";
import { buildClassName } from "../../helpers/className";
import { useDebug } from "../../hooks/useDebug";

/**
 * Login page component
 * @returns {React.JSX.Element}
 */
export default function LoginPage() {
    const { registrationUrl, csamSeparator } = useContent();
    const { currentStep, currentStage, currentInstance } = useAppState();
    const { debugMode } = useDebug();

    const [hasItsme] = useState(getStepChoices(currentStep)?.includes("itsme"));

    const is526 = currentInstance === INSTANCE_526;

    return (
        <Page>
            <PageBody center={is526}>
                <Spacer size={is526 ? "3rem" : "1.5rem"} />
                <LoginUsername />
                <Spacer size={is526 ? "1rem" : "1.5rem"} />
            </PageBody>
            {hasItsme || debugMode || currentStage === STAGE.CsamRedirect ? (
                <PageFooter className={buildClassName(is526 && "center")}>
                    {is526 ? (
                        <>
                            <Spacer size="1rem" />
                            <Separator>{csamSeparator}</Separator>
                        </>
                    ) : null}
                    <Spacer size={is526 ? "2rem" : "1.5rem"} />
                    <LoginCsam />
                    <Spacer size={is526 ? "2rem" : "1.5rem"} />
                </PageFooter>
            ) : null}
            {registrationUrl ? <LoginRegistration /> : null}
        </Page>
    );
}
