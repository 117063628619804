import { useMemo, useState } from "react";

import Button from "../../../components/atoms/button/button";
import Link from "../../../components/atoms/link/link";
import Spacer from "../../../components/atoms/spacer/spacer";
import Text from "../../../components/atoms/text/text";
import Title from "../../../components/atoms/title/title";
import { Form, FormControlText } from "../../../components/molecules/form/form";
import Page, { PageBody } from "../../../components/molecules/page/page";
import Alert from "../../../components/molecules/alert/alert";

import { INSTANCE_526 } from "../../../helpers/const";

import { useAppState } from "../../../hooks/useApp";
import { useForm } from "../../../hooks/useForm";
import { validateNationalRegistryNumber } from "../../../helpers/form/validation";
import { useContent } from "../../../hooks/useContent";

const nrnInputName = "national-registry-number";

export default function RecUsrNrnPage() {
    const [nrnSubmitLoading, setNrnSubmitLoading] = useState(false);
    const [nrnHelperOpen, setNrnHelperOpen] = useState(false);

    const {
        nrnLogoUrl,
        nrnTitle,
        nrnDescription,
        nrnFieldLabel,
        nrnFieldPlaceholder,
        nrnNextButton,
        nrnFieldHelperLabel,
        nrnFieldHelperContent,
        nrnRecoverPasswordLabel,
        nrnRecoverPasswordUrl,
        errorMessageNrnInvalid,
        errorMessageRequired,
    } = useContent();

    const { currentInstance } = useAppState();
    const { getInvalid, getErrors, setValue, getValue, validate, validateAll } =
        useForm({
            fields: [
                {
                    name: nrnInputName,
                    initialValue: "",
                    mask: "00.00.00-000.00",
                    validation: (value) => {
                        const errors = [];
                        if (!value) errors.push("required");
                        if (!validateNationalRegistryNumber(value))
                            errors.push("invalid");
                        return errors;
                    },
                },
            ],
        });

    const errorNrn = useMemo(() => {
        const errors = getErrors(nrnInputName);
        const errorKey = errors.length && errors[0];
        switch (errorKey) {
            case "required":
                return errorMessageRequired;
            case "invalid":
                return errorMessageNrnInvalid;
            default:
                return "";
        }
    }, [getErrors, errorMessageNrnInvalid, errorMessageRequired]);

    const is526 = currentInstance === INSTANCE_526;

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateAll(true);
        if (!isValid) return;
        setNrnSubmitLoading(true);
        const value = getValue(nrnInputName);
        console.log(value);
        // setStepInputValue(currentStep, CallbackType.NameCallback, value);
        // submitCurrentStep(currentStep);
    };

    return (
        <Page>
            <PageBody center={is526}>
                <Spacer size={is526 ? "3rem" : "1.5rem"} />
                {nrnLogoUrl ? (
                    <>
                        <img src={nrnLogoUrl} alt="logo" />
                        <Spacer size={is526 ? "2rem" : "1.5rem"} />
                    </>
                ) : null}
                <div style={is526 ? { padding: "0 2rem" } : null}>
                    <Title
                        size={is526 ? "medium-large" : "large"}
                        center={is526}
                    >
                        {nrnTitle}
                    </Title>
                    <Spacer size={is526 ? "3rem" : "1.5rem"} />
                    <Text weight="light" innerHtml={nrnDescription} />
                </div>
                {nrnHelperOpen ? (
                    <>
                        <Spacer size="2rem" />
                        <Alert
                            variant="info"
                            innerHtml={nrnFieldHelperContent}
                            onDismiss={() => setNrnHelperOpen(false)}
                        />
                    </>
                ) : null}
                <Spacer size="2rem" />
                <Form onSubmit={handleSubmit}>
                    <FormControlText
                        id={nrnInputName}
                        label={nrnFieldLabel}
                        type="text"
                        placeholder={nrnFieldPlaceholder}
                        error={errorNrn}
                        disabled={nrnSubmitLoading}
                        invalid={getInvalid(nrnInputName)}
                        value={getValue(nrnInputName, true)}
                        onChange={setValue(nrnInputName, true)}
                        onBlur={validate(nrnInputName, true)}
                        helper={
                            nrnFieldHelperLabel && nrnFieldHelperContent ? (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <Link
                                    size="small"
                                    truncate
                                    onClick={() => {
                                        setNrnHelperOpen(true);
                                    }}
                                >
                                    {nrnFieldHelperLabel}
                                </Link>
                            ) : null
                        }
                    />
                    <Button
                        type="submit"
                        fullSize={is526}
                        loading={nrnSubmitLoading}
                    >
                        {nrnNextButton}
                    </Button>
                </Form>
                {nrnRecoverPasswordLabel && nrnRecoverPasswordUrl ? (
                    <>
                        <Spacer size={is526 ? "2rem" : "1.5rem"} />
                        <Link href={nrnRecoverPasswordUrl} size="small">
                            {nrnRecoverPasswordLabel}
                        </Link>
                    </>
                ) : null}
                <Spacer size={is526 ? "2rem" : "1.5rem"} />
            </PageBody>
        </Page>
    );
}
