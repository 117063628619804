import React, { createElement } from "react";
import PropTypes from "prop-types";
import "./button.scss";
import { buildClassName } from "../../../helpers/className";
import Pending from "../../molecules/pending/pending";

/**
 * Button component
 * @param {object} props
 * @param {"primary"|"secondary"|"icon"|"text"} [props.variant="primary"]
 * @param {string} [props.type="button"]
 * @param {string} [props.value=""]
 * @param {number} [props.choiceIndex=null]
 * @param {boolean} [props.fullSize=false]
 * @param {boolean} [props.truncate=false]
 * @param {function} [props.onClick=null]
 * @param {string} [props.className=""]
 * @param {ReactNode} props.iconLeft
 * @param {ReactNode} props.iconRight
 * @param {ReactNode} props.children
 */
export default function Button({
    variant = "primary",
    type = "button",
    value = "",
    choiceIndex = null,
    fullSize = false,
    truncate = false,
    loading = false,
    onClick = null,
    href = null,
    className = "",
    iconLeft,
    iconRight,
    children,
}) {
    return createElement(
        href ? "a" : "button",
        {
            className: buildClassName(
                "button",
                className,
                variant && `variant-${variant}`,
                fullSize && "full-size",
                truncate && "truncate-text",
                loading && "loading",
            ),
            "data-choice-index": choiceIndex,
            onClick: (oEvent) => onClick && onClick(oEvent),
            href,
            type: href ? null : type,
            value,
        },
        <>
            {iconLeft && <span className="icon-left">{iconLeft}</span>}
            <span className="content">{children}</span>
            {iconRight && <span className="icon-right">{iconRight}</span>}
            {loading && (
                <span className="loading-icon">
                    <Pending button inverted={variant === "primary"} />
                </span>
            )}
        </>,
    );
}

Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.string,
    href: PropTypes.string,
    fullSize: PropTypes.bool,
    truncate: PropTypes.bool,
    loading: PropTypes.bool,
    iconLeft: PropTypes.node,
    iconRight: PropTypes.node,
    choiceIndex: PropTypes.number,
};
