import { useMemo, useState } from "react";

import { INSTANCE_526 } from "../../../helpers/const";

import { useAppState } from "../../../hooks/useApp";
import { useContent } from "../../../hooks/useContent";

import Page, { PageBody } from "../../../components/molecules/page/page";
import Spacer from "../../../components/atoms/spacer/spacer";
import Title from "../../../components/atoms/title/title";
import {
    Form,
    FormControlRadio,
} from "../../../components/molecules/form/form";
import Button from "../../../components/atoms/button/button";

export default function RecUsrChannelChoicePage() {
    const [submitLoading] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState("test1");
    const {
        channelChoiceTitle,
        channelChoiceLogoUrl,
        channelChoiceNextButton,
        channelChoiceSms,
        channelChoiceEmail,
    } = useContent();
    const { currentInstance } = useAppState();
    const is526 = currentInstance === INSTANCE_526;

    const choiceSmsFilled = useMemo(() => {
        if (!channelChoiceSms) return "";
        // To be replaced by a real phone number later
        return channelChoiceSms.replace("${phoneNumber}", "<0473*****89>"); // eslint-disable-line no-template-curly-in-string
    }, [channelChoiceSms]);

    const choiceEmailFilled = useMemo(() => {
        if (!channelChoiceEmail) return "";
        // To be replaced by a real email address later
        return channelChoiceEmail.replace(
            "${emailAddress}", // eslint-disable-line no-template-curly-in-string
            "<test**********@***gle.be>",
        );
    }, [channelChoiceEmail]);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e.target.value); // eslint-disable-line no-console
    };

    return (
        <Page>
            <PageBody center={is526}>
                <Spacer size={is526 ? "3rem" : "1.5rem"} />
                {channelChoiceLogoUrl ? (
                    <>
                        <img src={channelChoiceLogoUrl} alt="logo" />
                        <Spacer size={is526 ? "2rem" : "1.5rem"} />
                    </>
                ) : null}
                <div style={is526 ? { padding: "0 2rem" } : null}>
                    <Title
                        size={is526 ? "medium-large" : "large"}
                        center={is526}
                    >
                        {channelChoiceTitle}
                    </Title>
                </div>
                <Spacer size="2rem" />
                <Form onSubmit={handleSubmit}>
                    <FormControlRadio
                        options={[
                            { label: choiceSmsFilled, value: "test1" },
                            { label: choiceEmailFilled, value: "test2" },
                        ]}
                        selected={selectedChannel}
                        onChange={setSelectedChannel}
                    />
                    <Button
                        type="submit"
                        fullSize={is526}
                        loading={submitLoading}
                    >
                        {channelChoiceNextButton}
                    </Button>
                </Form>
                <Spacer size={is526 ? "2rem" : "1.5rem"} />
            </PageBody>
        </Page>
    );
}
