/* eslint-disable import/prefer-default-export */

import { useCallback, useState } from "react";
import { maskValue, unmaskValue } from "../helpers/form/mask";

/**
 * field config
 * @typedef {Object} FieldConfig
 * @property {string} name
 * @property {string} initialValue
 * @property {(value: any) => string[]} validation
 * @property {string} [mask]
 */

/**
 * useForm hook config
 * @typedef {Object} UseFormConfig
 * @property {FieldConfig[]} fields
 * @property {boolean} [validateOnBlur]
 * @property {boolean} [validateOnChange]
 */

/**
 * useForm hook return
 * @typedef {Object} UseFormReturn
 * @property {(fieldName: string, masked: boolean) => string} getValue
 * Get field `value` if `masked` is `true` it will return the masked value
 * @property {(fieldName: string, unmask: boolean) => (value: string) => void} setValue
 * Returns a `function` to set the field `value` if `unmask` is `true` it will unmask the received value
 * @property {(fieldName: string) => string[]} getErrors
 * Get field errors
 * - **If** `dirty == true`
 * @property {(fieldName: string) => boolean} getInvalid
 * Get field invalid status
 * - **If** `dirty == true`
 * @property {(fieldName: string, resetValue?: string) => void} reset
 * Reset field:
 * - `value = resetValue ?? initialValue`
 * - `errors = []`
 * - `dirty = false`
 * @property {(fieldName: string, setDirty?: boolean) => void} validate
 * Validate field
 * - `dirty = setDirty || field.dirty`
 * @property {(setDirty?: boolean) => boolean} validateAll
 * Validate all fields
 * - `dirty = setDirty || field.dirty`
 */

/**
 * Create form hook
 * @param {UseFormConfig} config
 * @returns {UseFormReturn}
 * @example
 * const { getValue, getErrors, getInvalid, setValue, validate, validateAll, reset } = useForm({
 *     fields: [
 *           {
 *               name: "username",
 *               initialValue: "",
 *               validation: (value) => !value && ["Username is required"],
 *           },
 *           {
 *               name: "password",
 *               initialValue: "",
 *               validation: (value) => !value && ["Password is required"],
 *           },
 *     ],
 * });
 *
 * const username = getValue("username");
 *
 * // Will only get errors if field is dirty
 * const usernameError = getErrors("username");
 *
 * // Will only get invalid status if field is dirty and has errors
 * const usernameInvalid = getInvalid("username");
 *
 * const setUsername = setValue("username");
 * setUsername("newUsername");
 *
 * const validateUsername = validate("username", true);
 * validateUsername() // validate field and set dirty
 *
 * function onSubmit() {
 *   if (!validateAll(true)) return;
 *   // submit form
 * }
 */
export function useForm(config) {
    const [fields, setFields] = useState(createFieldsFromConfig(config));

    const getValue = useCallback(
        (name, masked) => {
            checkFieldExists(fields, name);
            if (masked) return maskValue(fields[name].value, fields[name].mask);
            return fields[name].value;
        },
        [fields],
    );

    const setValue = useCallback(
        (name, unmask) => {
            checkFieldExists(fields, name);
            return (value) => {
                let rawValue = value;
                if (unmask) rawValue = unmaskValue(value, fields[name].mask);
                setFields((prevFields) => ({
                    ...prevFields,
                    [name]: {
                        ...prevFields[name],
                        value: rawValue,
                    },
                }));
            };
        },
        [fields],
    );

    const getErrors = useCallback(
        (name) => {
            checkFieldExists(fields, name);
            const field = fields[name];
            if (!field.dirty) return [];
            return field.errors;
        },
        [fields],
    );

    const getInvalid = useCallback(
        (name) => !!getErrors(name)?.length,
        [getErrors],
    );

    const validate = useCallback(
        (name, setDirty = false) =>
            () => {
                checkFieldExists(fields, name);
                setFields((prevFields) => {
                    const field = prevFields[name];
                    return {
                        ...prevFields,
                        [name]: {
                            ...field,
                            errors: field.validation(field.value),
                            dirty: setDirty || field.dirty,
                        },
                    };
                });
            },
        [fields],
    );

    const reset = useCallback(
        (name, resetValue) => {
            checkFieldExists(fields, name);
            setFields((prevFields) => ({
                ...prevFields,
                [name]: {
                    ...prevFields[name],
                    value: resetValue ?? prevFields[name].initialValue,
                    errors: [],
                    dirty: false,
                },
            }));
        },
        [fields],
    );

    const validateAll = useCallback(
        (setDirty = false) => {
            let isValid = true;
            const newFields = Object.keys(fields).reduce((acc, name) => {
                const field = fields[name];
                const errors = field.validation(field.value);
                if (errors.length) {
                    isValid = false;
                }
                return {
                    ...acc,
                    [name]: {
                        ...field,
                        errors,
                        dirty: setDirty || field.dirty,
                    },
                };
            }, {});
            setFields(newFields);
            return isValid;
        },
        [fields],
    );

    return {
        getValue,
        getErrors,
        getInvalid,
        setValue,
        validate,
        validateAll,
        reset,
    };
}

function createFieldsFromConfig(config) {
    return config.fields.reduce(
        (acc, field) => ({
            ...acc,
            [field.name]: {
                ...field,
                value: field.initialValue,
                dirty: false,
                errors: [],
            },
        }),
        {},
    );
}

function checkFieldExists(fields, name) {
    if (fields[name]) return;
    throw new Error(`Field ${name} does not exist`);
}
