import PropTypes from "prop-types";
import { createContext, useEffect, useMemo, useReducer } from "react";

/**
 * @typedef {Object} DebugState
 * @property {boolean} debugMode
 * @property {string} debugCurrentStage
 * @property {{id: string, label: string}[]} debugStages
 */

/**
 * @type {DebugState}
 */
const initialContext = {
    debugMode: false,
    debugCurrentStage: sessionStorage.getItem("debugStage") || undefined,
    debugStages: [],
};

/**
 * @type {React.Context<DebugState>}
 */
export const DebugContextState = createContext();
/**
 * @type {React.Context<(debugAction: DebugAction) => void>}
 */
export const DebugContextActions = createContext();

const savedRoute = sessionStorage.getItem("debugRoute");
export function DebugProvider({ children, environment }) {
    const [context, dispatch] = useReducer(debugReducer, initialContext);

    useMemo(() => {
        if (environment.loading) return;
        console.log("environment", environment.route);
        if (savedRoute === environment.route) return;
        sessionStorage.removeItem("debugStage");
        sessionStorage.setItem("debugRoute", environment.route);
    }, [environment.route]);

    useEffect(() => {
        if (context.debugMode === environment.magnolia?.editMode) return;
        dispatch({
            type: "SET_DEBUG_MODE",
            payload: environment.magnolia?.editMode,
        });
    }, [context, environment]);

    return (
        <DebugContextState.Provider value={context}>
            <DebugContextActions.Provider value={dispatch}>
                {children}
            </DebugContextActions.Provider>
        </DebugContextState.Provider>
    );
}

DebugProvider.propTypes = {
    children: PropTypes.node.isRequired,
    environment: PropTypes.shape({
        route: PropTypes.string,
        loading: PropTypes.bool,
        error: PropTypes.string,
        fesUrl: PropTypes.string,
        instance: PropTypes.string,
        locale: PropTypes.string,
        localeMap: PropTypes.objectOf(PropTypes.string),
        magnolia: PropTypes.shape({
            id: PropTypes.string,
            context: PropTypes.string,
            instanceId: PropTypes.string,
            editMode: PropTypes.bool,
        }),
    }).isRequired,
};

/**
 * @typedef {Object} DebugAction
 * @property {('SET_DEBUG_MODE'|'SET_DEBUG_STAGE'|'SET_DEBUG_STAGES')} type
 * @property {any} payload
 */

/**
 * @param {object} state
 * @param {object} action
 * @param {any} action.payload
 * @param {string} action.type
 * @returns {object}
 */
function debugReducer(state, action) {
    switch (action.type) {
        case "SET_DEBUG_MODE":
            return {
                ...state,
                debugMode: action.payload,
            };

        case "SET_DEBUG_STAGE": {
            sessionStorage.setItem("debugStage", action.payload);
            return {
                ...state,
                debugCurrentStage: action.payload,
            };
        }

        case "SET_DEBUG_STAGES":
            return {
                ...state,
                debugStages: action.payload,
            };

        default:
            return state;
    }
}
