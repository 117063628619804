/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { loadNotifications, loadPageData } from "../services/magnolia";

export const contextContent = createContext({});

/**
 * Content context provider
 * @param {Object} props
 * @param {Object} props.content
 * @param {React.ReactNode} props.children
 * @returns {React.ReactNode}
 */
export function ContentProvider({ environment, children }) {
    const [contentState, dispatch] = useReducer(contentReducer, {
        labels: [],
        notifications: [],
    });

    useEffect(() => {
        if (environment.loading || environment.error || !environment.locale)
            return;

        loadPageData({
            context: environment.magnolia.context,
            id: environment.magnolia.id,
            locale: environment.locale,
        }).then((pageJson) => {
            dispatch({ type: "SET_CONTENT", payload: pageJson });
        });

        loadNotifications({
            context: environment.magnolia.context,
            id: environment.magnolia.id,
            locale: environment.locale,
            editMode: environment.magnolia.editMode,
            instanceId: environment.magnolia.instanceId,
        }).then((response) => {
            dispatch({ type: "SET_NOTIFICATIONS", payload: response });
        });
    }, [environment]);

    return (
        <contextContent.Provider value={contentState}>
            {children}
        </contextContent.Provider>
    );
}

ContentProvider.propTypes = {
    environment: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.string,
        fesUrl: PropTypes.string,
        instance: PropTypes.string,
        locale: PropTypes.string,
        localeMap: PropTypes.objectOf(PropTypes.string),
        magnolia: PropTypes.shape({
            id: PropTypes.string,
            context: PropTypes.string,
            instanceId: PropTypes.string,
            editMode: PropTypes.bool,
        }),
    }).isRequired,
    children: PropTypes.node.isRequired,
};

function contentReducer(state, action) {
    switch (action.type) {
        case "SET_CONTENT":
            return { ...state, labels: action.payload };
        case "SET_NOTIFICATIONS":
            return { ...state, notifications: action.payload };
        default:
            return state;
    }
}
