import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { loadUTag } from "../services/utag";

/**
 * @typedef {Object} TaggingState
 * @property {Function} tagView
 * @property {Function} tagEvent
 * @property {string} error
 */

/**
 * @type {TaggingState}
 */
const initialContext = {
    tagView: null,
    tagEvent: null,
    error: undefined,
};

/**
 * @type {React.Context<TaggingState>}
 */
export const taggingContext = createContext();

export function TaggingProvider({ children, environment }) {
    const [context, setContext] = useState(initialContext);

    useEffect(() => {
        if (environment.loading) return;
        if (environment.instance === "nobody") {
            // Remove upper condition when ready and replace by the one below.
            // if (environment.instance === INSTANCE_509) {
            loadUTag()
                .then(() => {
                    setContext((state) => ({
                        ...state,
                        tagView: window.utag.view.bind(window.utag),
                        tagEvent: window.utag.link.bind(window.utag),
                    }));
                })
                .catch(() => {
                    setContext((state) => ({
                        ...state,
                        error: "Cannot load uTag",
                    }));
                });
        }
    }, [environment]);

    return (
        <taggingContext.Provider value={context}>
            {children}
        </taggingContext.Provider>
    );
}

TaggingProvider.propTypes = {
    children: PropTypes.node.isRequired,
    environment: PropTypes.shape({
        instance: PropTypes.string,
        loading: PropTypes.bool,
    }),
};
