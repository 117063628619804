import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";

import { PAGE } from "../config";

import {
    calculateDefaultLanguage,
    calculateInstance,
    trimSlashes,
} from "../helpers/app";
import { LANGUAGES } from "../helpers/const";
import useURL from "../hooks/useURL";

/**
 * @typedef {Object} EnvironmentState
 * @property {string} publicUrl
 * @property {string} fesUrl
 * @property {string} instance
 * @property {string} locale
 * @property {Object} localeMap
 * @property {Object} magnolia
 * @property {string} magnolia.id
 * @property {string} magnolia.context
 * @property {string} magnolia.host
 * @property {string} magnolia.instance
 * @property {boolean} magnolia.editMode
 * @property {Object} magnolia.api
 * @property {string} magnolia.api.templates
 * @property {string} magnolia.api.assets
 * @property {string} error
 * @property {boolean} loading
 */

/**
 * @type {EnvironmentState}
 */
const initialContext = {
    route: "",
    publicUrl: "/%PUBLIC_URL_DEFAULT_BASE%/.resources/webresources",
    fesUrl: "https://fes$instance-dev.m-team.be/login",
    instance: "",
    locale: "",
    localeMap: {},
    languages: [],
    magnolia: {
        id: "",
        context: "",
        host: "https://auth-dev.partenamut.be",
        instance: "author",
        editMode: false,
        api: {
            templates: "/.rest/template-annotations/v1",
            assets: "/dam",
        },
    },

    error: undefined,
    loading: true,
};

/**
 * @type {React.Context<EnvironmentState>}
 */
export const environmentContext = createContext();

/**
 * @type {React.Context<(envAction: EnvironmentAction) => void>}
 */
export const environmentDispatchContext = createContext();

export function EnvironmentProvider({ children }) {
    const [context, dispatch] = useReducer(environmentReducer, initialContext);
    const { hasAllParams } = useURL();

    // Set the env data from the HTML meta tags
    useEffect(() => {
        // TODO REFACTOR, This should not be inside a useEffect, this happens only on the page load
        try {
            const metaData = document.querySelector(
                'meta[name="configuration-data"]',
            );
            const data = JSON.parse(metaData?.content);

            let route = window.location.pathname;
            if (data.context === "/author") {
                route = route.replace(`/author/${data.instanceId}`, "");
            }
            const routeRemoveLocaleRegex = new RegExp(`^/${data.locale}`);
            route = route.replace(routeRemoveLocaleRegex, "");
            route = trimSlashes(route);

            // Set the 404 page if the route params are not correct
            // Only do this when not in edit mode
            const editMode = document.querySelector('meta[name="edit-mode"]');
            const isEditMode = editMode ? JSON.parse(editMode.content) : false;
            if (
                route === PAGE.login &&
                !isEditMode &&
                !(
                    hasAllParams("success") ||
                    hasAllParams("goto", "realm", "service") ||
                    hasAllParams("code", "iss", "scope", "state", "client_id")
                )
            ) {
                window.location.replace("/errors/404");
                return;
            }

            dispatch({
                type: "SET_ENV",
                payload: {
                    route,
                    fesUrl: data.fes,
                    localeMap: data.localeMap,
                    locale: data.locale,
                    magnolia: {
                        id: data.id,
                        context: data.context,
                        instance: data.instanceId,
                        editMode: isEditMode,
                    },
                    loading: false,
                },
            });
        } catch (err) {
            dispatch({
                type: "SET_ERROR",
                payload: { error: err.message, loading: false },
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <environmentContext.Provider value={context}>
            <environmentDispatchContext.Provider value={dispatch}>
                {children}
            </environmentDispatchContext.Provider>
        </environmentContext.Provider>
    );
}

EnvironmentProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

/**
 * @typedef {Object} EnvironmentAction
 * @property {('SET_ENV'|'UPDATE_LANGUAGE'|'SET_ERROR')} type
 * @property {any} payload
 */

/**
 * @param {EnvironmentState} state
 * @param {EnvironmentAction} action
 * @returns {EnvironmentState}
 */
function environmentReducer(state, action) {
    switch (action.type) {
        case "SET_ENV": {
            const currentInstance = calculateInstance(
                action.payload.magnolia.instance,
            );

            const availableLanguages = Object.keys(action.payload.localeMap);
            const languages = LANGUAGES.filter((l) =>
                availableLanguages.includes(l.code),
            );

            return {
                ...state,
                ...action.payload,
                instance: currentInstance,
                locale:
                    action.payload.locale ||
                    calculateDefaultLanguage(currentInstance),
                languages,
            };
        }

        case "UPDATE_LANGUAGE": {
            if (action.payload.forceReload) {
                window.location.pathname =
                    state.localeMap[action.payload.language];
                return state;
            }

            document
                .querySelector("html")
                .setAttribute("lang", action.payload.language);

            return {
                ...state,
                locale: action.payload.language,
            };
        }

        case "SET_ERROR": {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        }

        default: {
            // eslint-disable-next-line no-console
            console.warn(
                "Unknown action in Environment context",
                action.type,
                action.payload,
            );
            return state;
        }
    }
}
