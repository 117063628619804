import Cookies from "universal-cookie";
import {
    INSTANCE_509,
    INSTANCE_526,
    INSTANCE_AUTHOR,
    LANGUAGE_CODE_DUTCH,
    LANGUAGE_CODE_ENGLISH,
    LANGUAGE_CODE_FRENCH,
    LANGUAGE_CODES,
    PUBLIC_INSTANCES,
} from "./const";

const cookies = new Cookies();

export function calculateInstance(currentInstance) {
    if (PUBLIC_INSTANCES.includes(currentInstance)) {
        return currentInstance;
    }

    if (currentInstance === INSTANCE_AUTHOR) {
        // So, we try to find the currentInstance id trough URL (509, 526...)
        const { pathname } = window.location;

        return pathname
            .split("/")
            .find((elm) => PUBLIC_INSTANCES.includes(elm));
    }

    return "";
}

export function calculateDefaultLanguage(currentInstance) {
    if (currentInstance === INSTANCE_526) {
        return LANGUAGE_CODE_DUTCH;
    }

    if (currentInstance === INSTANCE_509) {
        return LANGUAGE_CODE_FRENCH;
    }

    return LANGUAGE_CODE_ENGLISH;
}

export function updateCookie(name, value, path = "/") {
    cookies.set(name, value, { path });
}

export function getCookie(name) {
    return cookies.get(name);
}

export function replaceCurrentLanguage(
    string,
    currentLanguage,
    nextLanguage = "",
) {
    return string.replace(
        new RegExp(`/${currentLanguage}($|/)`),
        `/${nextLanguage}`,
    );
}

export function findUrlLanguage() {
    const pathnameArray = window.location.pathname.split("/");
    let language = "";

    LANGUAGE_CODES.forEach((code) => {
        if (pathnameArray.includes(code)) {
            language = code;
        }
    });

    return language;
}

export function getMgnlVersion(path) {
    return new URLSearchParams(path).get("mgnlVersion");
}

export function updateMetaTitle(title) {
    const metaTitle = document.getElementById("metaTitle");
    metaTitle.innerText = title;
}

export function hasLangInURLOrInSearchParams(lang, searchParams) {
    return (
        new RegExp(`/${lang}($|/)`).test(window.location.pathname) ||
        searchParams.get("lang") === lang
    );
}

export function trimSlashes(value) {
    let newVal = value;
    if (!value) return "";
    if (typeof value !== "string") return value;
    if (value.startsWith("/")) {
        newVal = newVal.slice(1);
    }
    if (value.endsWith("/")) {
        newVal = newVal.slice(0, -1);
    }
    return newVal;
}
