import { useContext } from "react";
import { DebugContextActions, DebugContextState } from "../context/debug";

/**
 * @typedef {import("../context/debug").DebugState} DebugState
 */

/**
 * @returns {DebugState}
 * @description This hook is used to get the debug state from the context
 */
export function useDebug() {
    return useContext(DebugContextState);
}

export function useDebugActions() {
    const dispatch = useContext(DebugContextActions);

    /**
     * Set debug mode
     * @param {boolean} mode
     */
    const setDebugMode = (mode) => {
        dispatch({ type: "SET_DEBUG_MODE", payload: mode });
    };

    /**
     * Set debug stage
     * @param {string} stage
     */
    const setDebugStage = (stage) => {
        dispatch({ type: "SET_DEBUG_STAGE", payload: stage });
    };

    /**
     * Set debug stages
     * @param {(string|string[])[]} stages
     */
    const setDebugStages = (stages) => {
        const finalStagesObject = stages.map((stage) => {
            if (typeof stage === "string") {
                return {
                    id: stage,
                    label: stage,
                };
            }
            return {
                id: stage[0],
                label: stage.join(", "),
            };
        });
        dispatch({ type: "SET_DEBUG_STAGES", payload: finalStagesObject });
    };
    return {
        setDebugMode,
        setDebugStage,
        setDebugStages,
    };
}
